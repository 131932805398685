import React, { PureComponent } from "react";
import ControlTemplate from "./ControlTemplate";
import debounce from "debounce";
import { ThemedSlider } from "./Slider";
import * as utils from "./utils";

const MAX_ZOOM = 20;

class ZoomSlider extends PureComponent {
  static defaultProps = {
    buffer: null,
    windowStart: 0,
    windowEnd: 0,
    bufferStart: 0,
    duration: 0,
    margin: 5,
    onChangeWindow: null,
  };

  render() {
    let { margin, t } = this.props;
    let zoom = utils.getZoom(this.props);
    return (
      <ControlTemplate
        margin={margin}
        label={`${t("taggingapp.slider.zoom")}(${utils.roundToString(
          zoom,
          2
        )}x)`}
        control={
          <ThemedSlider
            value={zoom}
            step={0.1}
            min={1}
            max={MAX_ZOOM}
            onChange={this.onChangeSlider}
          />
        }
      />
    );
  }

  onChangeSlider = debounce((e, zoom) => {
    //buffer.duration ~ bufferDuration - has to be const 180 in order to achieve
    //proper behavior with shorter buffer.duration times
    let { bufferStart, windowStart, windowEnd, duration } = this.props;
    let bufferDuration = duration / zoom;
    let prevZoom = utils.getZoom(this.props);
    let half = (windowEnd - windowStart) / 2;
    let middle = windowStart + half;
    let newHalf = (half * prevZoom) / zoom;
    let newWindowStart = Math.max(middle - newHalf, bufferStart);
    let newWindowEnd = newWindowStart + Math.min(2 * newHalf, bufferDuration);
    if (newWindowEnd > bufferStart + bufferDuration) {
      newWindowEnd = bufferStart + bufferDuration;
      newWindowStart = Math.max(newWindowEnd - 2 * newHalf, bufferStart);
    }

    this.props.onChangeWindow &&
      this.props.onChangeWindow(newWindowStart, newWindowEnd);
  }, 400);
}

export default ZoomSlider;
