import { ICustomModel } from "types/customModels";

export enum EActions {
  fetchCustomModelsOverviewSuccess = "fetchCustomModelsOverviewSuccess",
  fetchCustomModelsOverviewFail = "fetchCustomModelsOverviewFail",
  fetchCustomModelsRequest = "fetchCustomModelsRequest",
}

export interface ICustomModelsState {
  error: boolean;
  customModels: ICustomModel[] | null;
  isFetching: boolean;
}

export interface ICustomModelsAction {
  type: EActions;
  payload: ICustomModelsState;
}

export type TReducer = {
  [key: string]: (p: ICustomModelsAction, s: ICustomModelsState) => void;
};
