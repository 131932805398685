import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import actions, {
  updateDataset,
  getSingleDataset,
  createDataset,
} from "store/eventSounds/actions";
import { Form } from "react-final-form";
import { ModalWrap } from "./modal";
import SelectionName from "./selectionName";
import ConfirmPopup from "components/confirmPopup";
import { ISelectedChunk } from "store/eventSounds/types";
import { IDatasetDetail } from "types/eventSounds";

const { setOpenModal } = actions;

const SaveDatasetModal = () => {
  const {
    openModal,
    selectedChunks,
    newName,
    existingName,
    datasetDetails,
    replaceExistingName,
  } = useSelector((state: AppState) => state.eventSounds);
  const { data: singleDataset, isFetching } = datasetDetails;
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const [alertPopup, setAlertPopup] = useState(false);

  const onCancel = useCallback(() => {
    dispatch(setOpenModal(false));
  }, [dispatch]);

  const onUpdate = useCallback(
    ({ name, data, id }: any) => {
      dispatch(
        updateDataset({
          name: name,
          datasetData: data,
          id: id,
        })
      );
    },
    [dispatch]
  );

  const datasetsDetail = singleDataset?.results?.map(
    (item: IDatasetDetail) => ({
      placement: item.placement,
      start: item.start,
      end: item.end,
    })
  );

  const data = selectedChunks.map((item: ISelectedChunk) => ({
    placement: item.placement,
    start: item.start,
    end: item.end,
  }));

  const onConfirm = useCallback(() => {
    const newData = data.filter(
      (item: any) =>
        !datasetsDetail.some(
          (existingItem: any) =>
            existingItem.placement === item.placement &&
            new Date(existingItem.start).toISOString() ===
              new Date(item.start).toISOString() &&
            new Date(existingItem.end).toISOString() ===
              new Date(item.end).toISOString()
        )
    );
    const newDatasets = [...datasetsDetail, ...newData];
    onUpdate({
      name: existingName.name,
      data: newDatasets,
      id: existingName.id,
    });
  }, [selectedChunks, existingName, datasetsDetail, data, onUpdate]);

  const closeConfirmation = useCallback(() => {
    setAlertPopup(false);
  }, []);

  const onSubmit = useCallback(() => {
    const checkingName = (name: any) => {
      return (
        name?.id === singleDataset?.id &&
        data.some((item: any) => {
          return singleDataset?.results?.some(
            (existingItem: any) =>
              existingItem.placement === item.placement &&
              new Date(existingItem.start).toISOString() ===
                new Date(item.start).toISOString() &&
              new Date(existingItem.end).toISOString() ===
                new Date(item.end).toISOString()
          );
        })
      );
    };

    if (existingName) {
      const isDataAlreadyExists = checkingName(existingName);
      if (isDataAlreadyExists) {
        setAlertPopup(true);
      } else {
        const newDatasets = [...datasetsDetail, ...data];
        onUpdate({
          name: existingName.name,
          data: newDatasets,
          id: existingName.id,
        });
      }
    } else if (replaceExistingName) {
      onUpdate({
        name: replaceExistingName.name,
        data: data,
        id: replaceExistingName.id,
      });
    } else {
      dispatch(
        createDataset({
          name: newName,
          datasetData: data,
        })
      );
    }
  }, [
    dispatch,
    datasetsDetail,
    datasetsDetail,
    newName,
    existingName,
    singleDataset,
    replaceExistingName,
    data,
    onUpdate,
  ]);

  useEffect(() => {
    if (existingName) {
      dispatch(getSingleDataset(existingName.id));
    } else if (replaceExistingName) {
      dispatch(getSingleDataset(replaceExistingName.id));
    }
  }, [dispatch, existingName, replaceExistingName]);
  return (
    <>
      <ModalWrap
        open={openModal}
        onCancel={onCancel}
        title={t("dataExplorer.dataset.modal.title")}
        cta={t("save")}
        onSubmit={onSubmit}
        submitDisabled={
          ((existingName || replaceExistingName) && isFetching) ||
          (!existingName && !replaceExistingName && !newName)
        }
      >
        <Box m={2}>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <SelectionName />
              </form>
            )}
          />
        </Box>
      </ModalWrap>
      {alertPopup && (
        <ConfirmPopup
          onConfirm={onConfirm}
          title={t("datasetGrouping.titles.duplicates")}
          text={t("datasetGrouping.titles.duplicatesText")}
          confirmText={t("datasetGrouping.actions.continue")}
          noControl={true}
          onCancel={closeConfirmation}
        />
      )}
    </>
  );
};
export default SaveDatasetModal;
