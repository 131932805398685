import { SortingRule } from "react-table";
import { IEventTypeList } from "types/event";
import {
  IDataSelection,
  IDatasetUpdate,
  IDatasetDetails,
  IDatasetGroup,
  IGroupedEventSoundList,
  ISimilaritySearch,
  IDataset,
} from "types/eventSounds";
import { IMachineList } from "types/machine";
import { IPlacementList, IPlacementType } from "types/placement";
import { ResponseType } from "types/response";
import { ISensorType } from "types/sensorType";

export enum EActions {
  fetchEventSoundsRequest = "fetchEventSoundsRequest",
  fetchEventSoundsFail = "fetchEventSoundsFail",
  fetchEventSoundsSuccess = "fetchEventSoundsSuccess",

  getPlacementTypesRequest = "getPlacementTypesRequest",
  getPlacementTypes = "getPlacementTypes",
  getMachineSubcategoryRequest = "getMachineSubcategoryRequest",
  getMachineSubcategory = "getMachineSubcategory",
  getFailureTypesRequest = "getFailureTypesRequest",
  getFailureTypes = "getFailureTypes",
  getEventTypesRequest = "getEventTypesRequest",
  getEventTypes = "getEventTypes",
  getTypesFail = "getTypesFail",

  getMachinesListRequest = "getMachinesListRequest",
  getMachinesList = "getMachinesList",
  getMachinesListFail = "getMachinesListFail",

  getPlacementsList = "getPlacementsList",

  getAudioChunkRequest = "getAudioChunkRequest",
  getAudioChunk = "getAudioChunk",

  setPlacementTypeValues = "setPlacementTypeValues",
  setMachineTypeValues = "setMachineTypeValues",
  setMachineValues = "setMachineValues",
  setFailureTypeValues = "setFailureTypeValues",
  setEventTypeValues = "setEventTypeValues",
  setLabelStart = "setLabelStart",
  setLabelEnd = "setLabelEnd",
  setSelectedChunks = "setSelectedChunks",
  setPage = "setPage",
  setMore = "setMore",
  setSearchParams = "setSearchParams",

  getSimilaritySoundsRequest = "getSimilaritySoundsRequest",
  getSimilaritySoundsSuccess = "getSimilaritySoundsSuccess",
  getSimilaritySoundsFail = "getSimilaritySoundsFail",

  setPlaying = "setPlaying",
  getLabelRequest = "getLabelRequest",
  getLabelFail = "getLabelFail",
  getMachines = "getMachines",
  getPlacements = "getPlacements",

  getSimilarityDetailRequest = "getSimilarityDetailRequest",
  getSimilarityDetailFail = "getSimilarityDetailFail",
  getSimilarityDetail = "getSimilarityDetail",

  getOriginalSound = "getOriginalSound",
  setOpenModal = "setOpenModal",
  setNewName = "setNewName",
  setExistingName = "setExistingName",
  setReplacingExistingName = "setReplacingExistingName",

  fetchDatasetsRequest = "fetchDatasetsRequest",
  fetchDatasetsFail = "fetchDatasetsFail",
  fetchDatasetsSuccess = "fetchDatasetsSuccess",

  fetchDatasetsDetailRequest = "fetchDatasetsDetailRequest",
  fetchDatasetsDetailFail = "fetchDatasetsDetailFail",
  fetchDatasetsDetailSuccess = "fetchDatasetsDetailSuccess",

  setDatasetParams = "setDatasetParams",
  setIsSearchedClicked = "setIsSearchedClicked",

  fetchDatasetGroupRequest = "fetchDatasetGroupRequest",
  fetchDatasetGroupFail = "fetchDatasetGroupFail",
  fetchDatasetGroupSuccess = "fetchDatasetGroupSuccess",

  fetchDatasetGroupDetailRequest = "fetchDatasetGroupDetailRequest",
  fetchDatasetGroupDetailFail = "fetchDatasetGroupDetailFail",
  fetchDatasetGroupDetailSuccess = "fetchDatasetGroupDetailSuccess",

  setSelectedDatasets = "setSelectedDatasets",

  setGroupedDatasetParams = "setGroupedDatasetParams",
  setCreationDateFrom = "setCreationDateFrom",
  setCreationDateTo = "setCreationDateTo",
  setQ = "setQ",

  setSort = "setSort",
  setHeaderCells = "setHeaderCells",
}

export type IFailureTypes = {
  name: string;
  id: number;
  description?: string | null;
};

export type ISelectedChunk = {
  placement: number;
  start: string;
  end: string;
};

export type IHeaderCells = {
  id: string;
  displayName: string;
  sortDirection?: "" | "desc" | "asc" | undefined;
};

export interface IEventSoundsState {
  eventSoundsData?: ResponseType<IDataSelection> | null;

  similaritySearch?: {
    data: ResponseType<ISimilaritySearch> | null;
    isFetching: boolean;
    error: Error | null;
  };

  isFetching?: boolean;
  error?: Error | null;
  pageIndex?: number;
  more?: number;
  placementTypes?: {
    data: IPlacementType[] | null;
    loading: boolean;
  };
  sensorTypes?: {
    data: ISensorType[] | null;
    loading: boolean;
  };
  machineSubcategories?: {
    data: IMachineList[];
    loading: boolean;
  };
  failureTypes?: {
    data: IFailureTypes[] | null;
    loading: boolean;
  };
  eventTypes?: {
    data: IEventTypeList[] | null;
    loading: boolean;
  };

  audioChunk: {
    data: IGroupedEventSoundList | null;
    loading: boolean;
  };

  placementTypeValues?: number[] | null;
  machineTypeValues?: number[] | null;
  failureTypeValues?: string[] | null;
  eventTypeValues?: string[] | null;
  machineValues?: number[] | null;
  labelStart?: Date | null;
  labelEnd?: Date | null;

  selectedChunks?: ISelectedChunk[];

  machinesList?: {
    data: IMachineList[] | null;
    loading: boolean;
    error?: Error | null;
  };
  placementsList?: IPlacementList[] | null;
  isFetchingEventDetails: boolean;
  errorEventDetails?: Error | null;

  searchParams?: {
    from?: Date | null;
    to?: Date | null;
    placement?: number | null;
  };
  playing: number | null;

  machines: IMachineList[] | null;
  placements: IPlacementList[] | null;
  labelFetching?: boolean;
  labelError?: Error | null;

  similaritySearchDetail?: {
    data: {
      placement: IPlacementList | null;
      machine: IMachineList | null;
    };
    loading: boolean;
    error?: Error | null;
  };

  originalSound: AudioBuffer | null;
  openModal: boolean;
  newName: string | null;
  existingName: IDatasetUpdate | null;
  replaceExistingName: IDatasetUpdate | null;

  datasets: {
    data: ResponseType<IDataset> | null;
    error: Error | null;
    isFetching: false;
  };

  datasetDetails: {
    data: IDatasetDetails | null;
    isFetching: boolean;
    error: Error | null;
  };

  isSearchedClicked: boolean;

  datasetParams: {
    page: number | null;
    pageSize: number | null;
    ids: number[] | null;
  };

  groupedDatasets: {
    data: IDatasetGroup[] | null;
    error: Error | null;
    isFetching: false;
  };

  groupedDatasetDetails: {
    data: IDatasetGroup | null;
    error: Error | null;
    isFetching: false;
  };

  selectedDatasets?: number[];

  groupedDatasetParams: {
    page: number | null;
    pageSize: number | null;
  };
  startDate?: Date | null;
  endDate?: Date | null;
  q?: string | null;
  sort: SortingRule<string>[];

  headerCells: IHeaderCells[];
}

export interface IEventSoundsAction {
  type: typeof EActions;
  payload: IEventSoundsState;
}

export type TReducers = {
  [key: string]: (p: IEventSoundsAction, s: IEventSoundsState) => void;
};

export type TUsersActionTypes = IEventSoundsAction;
