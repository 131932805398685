import React, { PureComponent } from "react";
import Tick from "./Tick";
import TickHour from "./TickHour";

class Ticks extends PureComponent {
  static defaultProps = {
    windowStart: 0,
    windowEnd: 0,
    width: 0,
    hideTimeLabel: false,
  };

  render() {
    let { windowStart, windowEnd, width, hideTimeLabel } = this.props;
    let duration = windowEnd - windowStart;
    let ticks = [];
    let pxPerSec = width / duration;
    let adjustedStart = Math.ceil(windowStart);
    let probeSize = [1, 3, 5, 10, 15, 30];
    let tickWidth = 0;
    let tickDuration = 0;

    // arbitral value between 180 - 3600
    // might be adjusted in future development
    if (duration <= 600) {
      while (tickWidth < 25 && probeSize.length) {
        tickDuration = probeSize.shift();
        tickWidth = pxPerSec * tickDuration;
      }
      while (adjustedStart % tickDuration !== 0) {
        adjustedStart++;
      }
      let left = (adjustedStart - windowStart) * pxPerSec;
      if (left !== 0) {
        ticks.push(<Tick key="pre" left={0} width={left} />);
      }
      while (left < width) {
        ticks.push(
          <Tick
            key={adjustedStart}
            left={left}
            width={tickWidth}
            timestamp={adjustedStart}
            widthLeft={width - left}
            hideTimeLabel={hideTimeLabel}
          />
        );
        left += tickWidth;
        adjustedStart += tickDuration;

        if (left > width) {
          ticks.push(
            <Tick
              key="post"
              left={left - tickWidth}
              width={tickWidth - (left - width)}
              hideTimeLabel={hideTimeLabel}
            />
          );
        }
      }
      return <div style={{ position: "relative" }}>{ticks}</div>;
    } else {
      probeSize = [60];
      while (tickWidth < 25 && probeSize.length) {
        tickDuration = probeSize.shift();
        tickWidth = pxPerSec * tickDuration;
      }
      while (adjustedStart % tickDuration !== 0) {
        adjustedStart++;
      }
      let left = (adjustedStart - windowStart) * pxPerSec;
      if (left !== 0) {
        ticks.push(<TickHour key="pre" left={0} width={left} />);
      }
      while (left < width) {
        ticks.push(
          <TickHour
            key={adjustedStart}
            left={left}
            width={tickWidth}
            timestamp={adjustedStart}
            widthLeft={width - left}
          />
        );
        left += tickWidth;
        adjustedStart += tickDuration;

        if (left > width) {
          ticks.push(
            <TickHour
              key="post"
              left={left - tickWidth}
              width={tickWidth - (left - width)}
            />
          );
        }
      }
      return <div style={{ position: "relative" }}>{ticks}</div>;
    }
  }
}

export default Ticks;
