import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {
  TableCell,
  TableRow,
  TableBody,
  Button,
  IconButton,
  MenuItem,
} from "@mui/material";
import { dateToStringShort } from "shared/helpers";
import { KebabMenu } from "components/kebab";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
} from "@mui/icons-material";
import { useStyles } from "components/eventSounds/components/table/styles";
import {
  fetchDatasets,
  removeGroupedDataset,
  updateGroupedDataset,
} from "store/eventSounds/actions";
import Spinner from "components/spinner";
import ConfirmPopup from "components/confirmPopup";
import EditModal from "./editModal";
import { IDataset } from "types/eventSounds";
import TrainingModel from "./oneClickTraining";
import actions from "store/customModelCategory/actions";
import { v4 as uuidv4 } from "uuid";

const { setModelUrls, setModelCategory, setTraceId } = actions;

const GroupedDatasetTableBody = ({
  groupedDataset,
  id,
  itemClicked,
  setItemClicked,
  isItemClicked,
  setIsItemClicked,
  itemClickedIds,
  setItemClickedIds,
}: any) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const classes = useStyles();
  const [deleteModal, setDeleteModal] = useState(false);
  const [removeDatasetModal, setRemoveDatasetModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [trainModal, setTrainModal] = useState(false);
  const [datasetId, setDatasetId] = useState(null);
  const [groupedDatasetName, setGroupedDatasetName] = useState("");

  const { datasets } = useSelector((state: AppState) => state.eventSounds);
  const { data: datasetList, isFetching } = datasets;

  const hideKebabMenu = useCallback(() => {
    const hideKebab = new CustomEvent("hideKebab");
    const kebab = document.querySelector(
      "#kebab-devices-list-" + groupedDataset.id
    );
    kebab?.dispatchEvent(hideKebab);
  }, [groupedDataset.id]);

  useEffect(() => {
    if (itemClicked === groupedDataset.id && groupedDataset.datasets.length) {
      const datasetIds = groupedDataset.datasets.map(
        (item: { dataset: number }) => item.dataset
      );

      dispatch(
        fetchDatasets({
          pageSize: 9999,
          ids: datasetIds,
        })
      );
    }
  }, [dispatch, itemClicked, groupedDataset]);

  const onDelete = useCallback(() => {
    hideKebabMenu();
    setDeleteModal(true);
  }, [hideKebabMenu, groupedDataset.id]);

  const deleteDatasetGroup = useCallback(() => {
    setDeleteModal(false);
    dispatch(removeGroupedDataset(groupedDataset.id));
  }, [dispatch, groupedDataset.id]);

  const onRemoveDatasetFromGroups = useCallback((dataset: any) => {
    setRemoveDatasetModal(true);
    setDatasetId(dataset);
  }, []);

  const handleRemoveDatasetFromGroups = useCallback(() => {
    const newDataset = groupedDataset.datasets.filter(
      (item: { dataset: number }) => item.dataset !== datasetId
    );
    const newData = {
      name: groupedDataset.name,
      id: groupedDataset.id,
      datasets: newDataset,
      openNewTab: false,
    };
    dispatch(updateGroupedDataset(newData));
  }, [dispatch, datasetId, groupedDataset]);

  const closeDatasetMenu = useCallback(() => {
    setRemoveDatasetModal(false);
  }, []);

  const closeMenu = useCallback(() => {
    setDeleteModal(false);
  }, []);

  const onEdit = useCallback(() => {
    hideKebabMenu();
    setEditModal(true);
  }, [hideKebabMenu, groupedDataset.id]);

  const onTrain = useCallback(() => {
    hideKebabMenu();
    setTrainModal(true);
    dispatch(setTraceId(uuidv4()));
  }, [dispatch, hideKebabMenu, groupedDataset.id]);

  const closeEditMenu = useCallback(() => {
    setEditModal(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setTrainModal(false);
    dispatch(setModelUrls([]));
    dispatch(setModelCategory(""));
    dispatch(setTraceId(null));
  }, [dispatch]);

  const handleEditSubmit = useCallback(() => {
    handleCloseModal();
    const newData = {
      name: groupedDatasetName,
      id: groupedDataset.id,
      datasets: groupedDataset.datasets,
      openNewTab: false,
    };
    dispatch(updateGroupedDataset(newData));
  }, [
    dispatch,
    groupedDataset.id,
    groupedDatasetName,
    groupedDataset.datasets,
    handleCloseModal,
  ]);

  useEffect(() => {
    setGroupedDatasetName(groupedDataset.name);
  }, [dispatch, groupedDataset.name]);

  const generateItems = useMemo(() => {
    const tt = (i: string) => t(`groupedDataset.${i}`);
    return (
      <>
        <MenuItem>
          <Button
            size="small"
            className={classes.actionButton}
            onClick={onEdit}
          >
            {tt("edit")}
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            size="small"
            className={classes.actionButton}
            onClick={onDelete}
          >
            {tt("delete")}
          </Button>
        </MenuItem>
        {groupedDataset.result_count ? (
          <MenuItem>
            <Button
              size="small"
              className={classes.actionButton}
              onClick={onTrain}
            >
              {tt("trainCustomModel")}
            </Button>
          </MenuItem>
        ) : null}
      </>
    );
  }, [
    classes.actionButton,
    groupedDataset.result_count,
    onDelete,
    onEdit,
    onTrain,
    t,
  ]);

  return (
    <>
      <thead className={classes.break} />
      <TableBody>
        <TableRow className={classes.tr}>
          <TableCell className={classes.td}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setItemClicked(groupedDataset.id);
                setIsItemClicked(!isItemClicked);

                setItemClickedIds(
                  itemClickedIds.includes(groupedDataset.id)
                    ? []
                    : [groupedDataset.id]
                );
              }}
              disabled={!groupedDataset.result_count}
            >
              {itemClicked === id && itemClickedIds.includes(itemClicked) ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </IconButton>
          </TableCell>
          <TableCell
            colSpan={1}
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {groupedDataset.name}
          </TableCell>
          <TableCell
            colSpan={1}
            className={`${classes.smallPadding} ${classes.td}`}
            align="center"
          >
            {groupedDataset.result_count}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {dateToStringShort(groupedDataset.created_at)}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.td}`}
            align="left"
          >
            {dateToStringShort(groupedDataset.modified_at)}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.td}`}
            align="right"
          >
            <KebabMenu
              id={"kebab-devices-list-" + groupedDataset.id}
              data={generateItems}
            />
          </TableCell>
        </TableRow>
        {itemClicked === id && itemClickedIds.includes(itemClicked) ? (
          isFetching ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Spinner />
              </TableCell>
            </TableRow>
          ) : (
            datasetList?.results?.map((item: IDataset) => {
              return (
                <>
                  <TableRow>
                    <TableCell
                      className={`${classes.td} ${classes.tdCollapsed} ${classes.smallPadding}`}
                    ></TableCell>
                    <TableCell
                      colSpan={1}
                      align="left"
                      className={`${classes.td} ${classes.tdCollapsed} ${classes.smallPadding}`}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      align="center"
                      className={`${classes.td} ${classes.tdCollapsed} ${classes.smallPadding}`}
                    >
                      {item.result_count}
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      className={`${classes.td} ${classes.tdCollapsed} ${classes.smallPadding}`}
                      align="right"
                      style={{ paddingRight: "5rem" }}
                    >
                      <Button
                        onClick={() => onRemoveDatasetFromGroups(item.id)}
                      >
                        <Delete />
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              );
            })
          )
        ) : null}
      </TableBody>

      {deleteModal && (
        <ConfirmPopup
          onConfirm={deleteDatasetGroup}
          title={t("groupedDataset.titles.delete")}
          text={t("groupedDataset.titles.deleteText")}
          confirmText={t("groupedDataset.actions.delete")}
          noControl={true}
          onCancel={closeMenu}
        />
      )}
      {removeDatasetModal && (
        <ConfirmPopup
          onConfirm={handleRemoveDatasetFromGroups}
          title={t("groupedDataset.dataset.titles.delete")}
          text={t("groupedDataset.dataset.titles.deleteText")}
          confirmText={t("groupedDataset.dataset.actions.delete")}
          noControl={true}
          onCancel={closeDatasetMenu}
        />
      )}

      <TrainingModel
        open={trainModal}
        closeModal={handleCloseModal}
        id={groupedDataset.id}
        datasetClasses={groupedDataset.datasets}
      />

      <EditModal
        groupedDatasetName={groupedDatasetName}
        setGroupedDatasetName={setGroupedDatasetName}
        open={editModal}
        onCancel={closeEditMenu}
        onSubmit={handleEditSubmit}
      />
    </>
  );
};

export default GroupedDatasetTableBody;
