import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepConnector from "@mui/material/StepConnector";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTranslation } from "react-i18next";
import IconButton from "components/iconButton";
import Button from "components/button";
import StepLabel from "./stepLabel";
import { StepType } from "components/stepModal";
import IMuiTheme from "types/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: IMuiTheme | Theme) => ({
  mobileHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5, 1),
  },
  mobileHeaderTitle: {
    fontFamily: theme.custom.typography.fontFamily,
    fontSize: theme.custom.typography.fontSize[18],
    fontWeight: theme.custom.typography.fontWeight.medium,
    marginLeft: theme.spacing(4),
    flexShrink: 0,
  },
  mobileContent: {
    fontFamily: theme.custom.typography.fontFamily,
    boxShadow: theme.custom.boxShadow[2],
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowY: "scroll",
  },
  mobileStepper: {
    padding: 0,
    "& > .MuiStepConnector-vertical": {
      display: "none",
    },
  },
  mobileStepperStepContentWrapper: {
    paddingHorizontal: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    paddingBottom: "1.5rem",
    marginBottom: "0.5rem",
  },
  stepButtons: {
    display: "flex",
    marginTop: theme.spacing(1),
  },
  stepButton: {
    margin: theme.spacing(0, 1),
  },
}));

type Props<T> = {
  modalTitle: string;
  steps: StepType[];
  activeStep: number;
  finishButtonDataTestId?: string;
  finishButtonLabel: string;
  onNext: () => void;
  goToNextStep: () => void;
  handleActive: (id: number) => void;
  onCancel: () => void;
  handleFinish?: () => void;
  setData: (data?: T) => void;
  data?: T;
};

const Connector = withStyles((theme: any) => ({
  line: {
    borderColor: theme.custom.palette.placeholder,
  },
}))(StepConnector);

const MobileView = <T extends {}>({
  steps,
  activeStep,
  onNext,
  goToNextStep,
  onCancel,
  handleFinish,
  finishButtonDataTestId,
  finishButtonLabel,
  handleActive,
  modalTitle,
  setData,
  data,
}: Props<T>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const lastStep = activeStep === steps.length - 1;

  return (
    <>
      <div className={classes.mobileHeader}>
        <IconButton onClick={onCancel}>
          <CloseRoundedIcon />
        </IconButton>
        <span className={classes.mobileHeaderTitle}>{modalTitle}</span>
      </div>
      <div className={classes.mobileContent}>
        <Stepper
          connector={<Connector />}
          className={classes.mobileStepper}
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map(({ label, Component }, index) => (
            <Step key={label}>
              <StepLabel
                handleActive={handleActive}
                label={label}
                index={index}
                activeStep={activeStep}
              />
              <StepContent className={classes.mobileStepperStepContentWrapper}>
                <div>
                  <Component
                    onSuccess={(data: any) => {
                      setData(data);
                      goToNextStep();
                    }}
                    id={steps[activeStep].formId}
                    data={data}
                  />
                </div>
                <div className={classes.stepButtons}>
                  <div className={classes.stepButton}>
                    <Button
                      color="secondary"
                      dataTestId={lastStep ? finishButtonDataTestId : undefined}
                      onClick={lastStep ? handleFinish : onNext}
                      disabled={lastStep ? !handleFinish : false}
                    >
                      {lastStep ? finishButtonLabel : t("Continue")}
                    </Button>
                  </div>
                  <div className={classes.stepButton}>
                    <Button onClick={onCancel}>{t("Cancel")}</Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    </>
  );
};

export default MobileView;
