import { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { Card } from "./card";
import actions from "store/eventSounds/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid grey`,
    height: 204,

    "& .canvases canvas": {
      height: "31px",
      backgroundColor: "red",
    },
  },
  selected: {
    borderColor: theme.custom.palette.success.regular,
    borderWidth: "3px",
    borderStyle: "solid",
    borderRadius: 5,
  },
  wrapper: {
    borderColor: "transparent",
    borderWidth: "3px",
    borderStyle: "solid",
  },
}));

const DataChunk = ({ buffer, setIsPlaying, isPlaying, setPlayingAll }: any) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const { selectedChunks } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const isSelected = selectedChunks.some(
    (chunk: any) =>
      chunk.placement === buffer.placement &&
      chunk.machine === buffer.machine &&
      new Date(chunk.start).toISOString() ===
        new Date(buffer.audio_chunk.start_datetime).toISOString() &&
      new Date(chunk.end).toISOString() ===
        new Date(buffer.audio_chunk.end_datetime).toISOString()
  );

  const handleCardClick = useCallback(
    (event: any, buffer: any) => {
      event.stopPropagation();
      event.preventDefault();
      if (isSelected) {
        dispatch(
          actions.setSelectedChunks(
            selectedChunks.filter(
              (item: any) =>
                !(
                  item.placement === buffer.placement &&
                  item.machine === buffer.machine &&
                  new Date(item.start).toISOString() ===
                    new Date(buffer.audio_chunk.start_datetime).toISOString() &&
                  new Date(item.end).toISOString() ===
                    new Date(buffer.audio_chunk.end_datetime).toISOString()
                )
            )
          )
        );
      } else {
        dispatch(
          actions.setSelectedChunks([
            ...selectedChunks,
            {
              placement: buffer.placement,
              start: buffer.audio_chunk.start_datetime,
              end: buffer.audio_chunk.end_datetime,
              machine: buffer.machine,
            },
          ])
        );
      }
    },
    [dispatch, selectedChunks, isSelected]
  );

  return (
    <div
      id={buffer.id}
      onClick={(event) => handleCardClick(event, buffer)}
      className={isSelected ? classes.selected : classes.wrapper}
    >
      <Card
        chunk={buffer}
        id={buffer.id}
        setIsPlaying={setIsPlaying}
        isPlaying={isPlaying}
        setPlayingAll={setPlayingAll}
      />
    </div>
  );
};
export default DataChunk;
