import React, { useState, useCallback, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Modal } from "components/modal";
import DesktopView from "./parts/desktopView";
import MobileView from "./parts/mobileView";

export interface ContentProps<T> {
  onSuccess: (data?: T) => void;
  id?: string;
  data?: T;
}

export type StepType = {
  label: string;
  formId?: string;
  Component: (params: ContentProps<any>) => JSX.Element;
};

type Props<T> = {
  modalTitle: string;
  steps: StepType[];
  open: boolean;
  finishButtonDataTestId?: string;
  finishButtonLabel: string;
  handleFinish?: (data?: T) => void;
  closeModal: () => void;
  onProgress?: (callback?: () => void) => void;
  loading?: boolean;
};

const StepModal = <T extends {}>(props: Props<T>) => {
  const {
    open,
    closeModal,
    steps,
    finishButtonDataTestId,
    finishButtonLabel,
    handleFinish,
    modalTitle,
    onProgress,
    loading,
    ...rest
  } = props;

  const theme = useTheme();

  const showDesktopVersion = useMediaQuery(
    `only screen and (min-width: ${theme.spacing(120)})`
  );

  const [activeStep, setActiveStep] = useState<number>(0);
  const dataRef = useRef<T>();

  const handleSetData = useCallback((data?: T) => {
    if (data) dataRef.current = { ...dataRef.current, ...data };
  }, []);

  const goToNextStep = useCallback(() => {
    document.getElementById(`button-${steps[activeStep].formId}`)?.click();
    setTimeout(() => {
      setActiveStep((prevActiveStep: any) => {
        return prevActiveStep + 1 === steps.length
          ? prevActiveStep
          : prevActiveStep + 1;
      });
    }, 1000);
  }, [activeStep, steps]);

  const triggerFormSubmission = useCallback(() => {
    const formElement = document.getElementById(steps[activeStep].formId || "");
    if (formElement) {
      const submitEvent = new CustomEvent("submit", {
        cancelable: true,
      });
      formElement.dispatchEvent(submitEvent);
    }
  }, [steps, activeStep]);

  const handleNext = useCallback(() => {
    if (steps[activeStep].formId === "machine-summary-form") {
      triggerFormSubmission();
    } else {
      goToNextStep();
    }
  }, [triggerFormSubmission, goToNextStep, steps, activeStep]);

  const handleActive = useCallback((id: number) => setActiveStep(id), []);

  const onCancel = useCallback(() => {
    setActiveStep(0);
    dataRef.current = undefined;
    closeModal();
  }, [closeModal]);

  const onFinish = useCallback(() => {
    handleFinish && handleFinish(dataRef.current);
    dataRef.current = undefined;
    setActiveStep(0);
  }, [handleFinish]);

  return (
    <Modal
      onCancel={onCancel}
      open={open}
      loading={loading}
      width={120}
      {...rest}
    >
      {showDesktopVersion ? (
        <DesktopView<T>
          onCancel={onCancel}
          activeStep={activeStep}
          onNext={handleNext}
          goToNextStep={goToNextStep}
          handleActive={handleActive}
          finishButtonDataTestId={finishButtonDataTestId}
          finishButtonLabel={finishButtonLabel}
          handleFinish={handleFinish && onFinish}
          steps={steps}
          setData={handleSetData}
          data={dataRef.current}
        />
      ) : (
        <MobileView
          steps={steps}
          onCancel={onCancel}
          activeStep={activeStep}
          onNext={handleNext}
          goToNextStep={goToNextStep}
          handleActive={handleActive}
          finishButtonDataTestId={finishButtonDataTestId}
          finishButtonLabel={finishButtonLabel}
          handleFinish={handleFinish && onFinish}
          modalTitle={modalTitle}
          setData={handleSetData}
          data={dataRef.current}
        />
      )}
    </Modal>
  );
};

export default StepModal;
