import { IDeviceList } from "types/device";

export enum EActions {
  setDevice = "setDevice",
  setLoading = "setLoading",
  setReset = "setReset",
  setError = "setError",
}

export interface DeviceDetailState {
  device?: IDeviceList;
  loading?: boolean;
  error?: Error;
}

export interface DevicesDetailAction {
  type: typeof EActions;
  payload: {
    device: IDeviceList;
    loading: boolean;
    error: Error;
  };
}

export type TReducers = {
  [key: string]: (
    p: DevicesDetailAction,
    s: DeviceDetailState
  ) => DeviceDetailState;
};
