import { EActions, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState = {
  device: undefined,
  loading: false,
  error: undefined,
};

const reducers: TReducers = {
  [EActions.setDevice]: ({ payload: { device } }) => ({
    device,
    loading: false,
  }),
  [EActions.setLoading]: ({ payload: { loading } }) => ({
    loading,
  }),
  [EActions.setError]: ({ payload: { error } }) => ({
    error,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
};

export default createReducer(path, initialState, reducers);
