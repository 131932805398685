import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import actions from "store/eventSounds/actions";
import { Box } from "@mui/material";
import { CardDetail } from "../chunkDetail";
import { Player } from "components/eventSounds/components/Player";
import { getPeaks } from "components/eventSounds/components/card";
import { IModifiedSimilaritySearch } from "types/eventSounds";
import { useStyles } from "components/eventSounds/components/styles";

interface Props {
  chunk: IModifiedSimilaritySearch;
  setIsPlaying: (id: number) => (value: boolean) => any;
}

const ChunkCard: React.FC<Props> = (props) => {
  const { chunk, setIsPlaying } = props;
  const classes = useStyles();
  const { selectedChunks } = useSelector(
    (state: AppState) => state.eventSounds
  );
  const dispatch: any = useDispatch();

  const min_distance = Math.min(...chunk.matches.map((i: any) => i.distance));

  const isSelected = selectedChunks.some(
    (item: any) =>
      item.placement === chunk.placement &&
      new Date(item.start).toISOString() ===
        new Date(chunk.start_datetime).toISOString() &&
      new Date(item.end).toISOString() ===
        new Date(chunk.end_datetime).toISOString()
  );

  const handleCardClick = useCallback(
    (event: any, buffer: any) => {
      event.stopPropagation();
      event.preventDefault();

      if (isSelected) {
        dispatch(
          actions.setSelectedChunks(
            selectedChunks.filter(
              (item: any) =>
                !(
                  item.placement === buffer.placement &&
                  new Date(item.start).toISOString() ===
                    new Date(buffer.start_datetime).toISOString() &&
                  new Date(item.end).toISOString() ===
                    new Date(buffer.end_datetime).toISOString()
                )
            )
          )
        );
      } else {
        dispatch(
          actions.setSelectedChunks([
            ...selectedChunks,
            {
              placement: buffer.placement,
              start: buffer.start_datetime,
              end: buffer.end_datetime,
            },
          ])
        );
      }
    },
    [dispatch, selectedChunks, isSelected]
  );

  return (
    <div
      id={String(chunk.id)}
      onClick={(event) => handleCardClick(event, chunk)}
      className={isSelected ? classes.selected : classes.wrapper}
    >
      <Box className={classes.container} key={chunk.id}>
        <Player
          buffer={chunk.buffer}
          getPeaks={getPeaks}
          canvasWidth={145}
          canvasWaveHeight={20}
          canvasSpectroHeight={126}
          isPlaying={chunk.isPlaying}
          setIsPlaying={setIsPlaying(chunk.id)}
        />
        <div style={{ position: "absolute", top: ".5%", right: "1%" }}>
          <CardDetail
            id={chunk.id}
            min_distance={min_distance}
            classes={classes}
            chunk={chunk}
          />
        </div>
      </Box>
    </div>
  );
};

export default ChunkCard;
