import React, { PureComponent } from "react";
import Tooltip from "@mui/material/Tooltip";
import Button from "components/button";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import { createStyles, withStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";

class Labels extends PureComponent {
  static defaultProps = {
    labels: [],
    selected: {},
    onChangeLabel: null,
    onRemoveAllLabels: null,
    onCopyLink: null,
    onSearch: null,
  };

  state = {
    inputVisible: false,
    inputValue: "",
  };

  input = null;

  saveInputRef = (input) => (this.input = input);

  showInput = () => {
    this.setState(
      { ...this.state, inputVisible: true },
      () => this.input && this.input.focus()
    );
  };

  handleInputChange = (e) => {
    this.setState({ ...this.state, inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    let { onChangeLabel, selected, labelChannels } = this.props;
    let { inputValue } = this.state;
    let label = inputValue.trim();
    this.setState(
      { ...this.state, inputVisible: false, inputValue: "" },
      () => {
        if (onChangeLabel && label && !selected[label]) {
          labelChannels &&
            labelChannels.map((channel) => {
              return onChangeLabel(label, true, channel /*labelChannels*/);
            });
        }
      }
    );
  };

  render() {
    let { labels, selected, placement, processing, t } = this.props;
    let maxLabelLength = 15;
    return (
      <div
        id={`idLabelsContainer${placement.value}`}
        style={{ lineHeight: "27px" }}
      >
        {labels
          .filter(({ name }) => name[0] !== "$")
          .map(({ name, id }) => {
            const isLongLabel = name.length > maxLabelLength;
            const labelElem = (
              <ThemedChip
                key={name}
                checked={selected[name]}
                label={
                  isLongLabel ? `${name.slice(0, maxLabelLength)}...` : name
                }
                clickable
                onClick={() =>
                  !processing &&
                  this.onChange(name, !selected[name], placement, id)
                }
              />
            );
            return isLongLabel ? (
              <Tooltip title={name} key={name}>
                {labelElem}
              </Tooltip>
            ) : (
              labelElem
            );
          })}

        <Box m={1}>
          <Button
            color="secondary"
            id="idRemoveAllLabels"
            onClick={() =>
              this.props.onRemoveAllLabels && this.props.onRemoveAllLabels()
            }
          >
            {t("taggingapp.labels.removeAll")}
          </Button>
          <Button
            color="secondary"
            id="idCopyLabelLink"
            onClick={() => this.props.onCopyLink && this.props.onCopyLink()}
          >
            {t("taggingapp.labels.copyLink.button")}
          </Button>
          <Button
            color="secondary"
            id="idSearchLabels"
            onClick={() => this.props.onSearch && this.props.onSearch()}
          >
            {t("taggingapp.labels.search")}
          </Button>
        </Box>
      </div>
    );
  }

  onChange = (item, checked, channel, id) => {
    const { onChangeLabel } = this.props;
    onChangeLabel && onChangeLabel(item, checked, channel, id);
  };
}
const mapStateToProps = ({ player }) => ({
  processing: player.processing,
});

const chipStyles = (theme) =>
  createStyles({
    root: {
      background: ({ checked }) => {
        return checked
          ? theme.custom.palette.gradient.primary.default
          : theme.custom.palette.secondary;
      },
      borderRadius: theme.spacing(0.5),
      color: ({ checked }) => {
        return checked
          ? theme.custom.palette.secondary
          : theme.custom.palette.primary["100"];
      },
      padding: theme.spacing(0.7, 1),
      margin: theme.spacing(0.2, 0.4),
      boxShadow: theme.custom.boxShadow["2"],
    },
  });

const ThemedChip = withStyles(chipStyles)(Chip);

export default connect(mapStateToProps)(withTranslation()(Labels));
