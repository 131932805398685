import { EActions, TReducer, ICustomModelsState } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

export const initialState: ICustomModelsState = {
  error: false,
  customModels: [],
  isFetching: false,
};

const reducers: TReducer = {
  [EActions.fetchCustomModelsRequest]: () => ({
    isFetching: true,
  }),
  [EActions.fetchCustomModelsOverviewFail]: ({ payload: { error } }) => ({
    customModels: null,
    error: error,
    isFetching: false,
  }),
  [EActions.fetchCustomModelsOverviewSuccess]: ({
    payload: { customModels },
  }) => ({
    customModels,
    error: null,
    isFetching: false,
  }),
};

export default createReducer<ICustomModelsState>(path, initialState, reducers);
