import React, { PureComponent } from "react";
import Region from "./Region";
import RegionSimplified from "./RegionSimplified";
import * as utils from "./utils";
class Regions extends PureComponent {
  static defaultProps = {
    windowStart: 0,
    pxPerSec: 0,
    height: 0,
    bottom: 0,

    regions: [],
    selectedRegion: null,
    onSelectRegion: null,
    onRemoveAllLabels: null,
    onDragRegion: null,
    onResizeRegion: null,
  };

  render() {
    let {
      regionStyle,
      selectedRegionStyle,
      height,
      selectedRegion,
      onSelectRegion,
      onRemoveAllLabels,
      onDragRegion,
      onResizeRegion,
      hourView,
    } = this.props;
    let lines = this.splitIntoLines();
    let lineHeight = height / Math.max(5, lines.length);

    return (
      <React.Fragment>
        {lines.map((line, i) =>
          line.map((region) =>
            hourView ? (
              <RegionSimplified
                key={`${i}-${region.start}`}
                style={regionStyle}
                selectedStyle={selectedRegionStyle}
                region={region}
                id={`regSimp-id-${i}-${region.start}`}
                {...this.calculationRegionPosition(i, lineHeight, region)}
                selected={
                  selectedRegion && this.equalRegions(selectedRegion, region)
                    ? true
                    : false
                }
                onDragRegion={onDragRegion}
                onSelectRegion={onSelectRegion}
              />
            ) : (
              <Region
                key={`${i}-${region.start}`}
                style={regionStyle}
                selectedStyle={selectedRegionStyle}
                region={region}
                onRemoveAllLabels={onRemoveAllLabels}
                selected={
                  selectedRegion && this.equalRegions(selectedRegion, region)
                    ? true
                    : false
                }
                {...this.calculationRegionPosition(i, lineHeight, region)}
                {...(onSelectRegion ? { onClick: onSelectRegion } : {})}
                id={`reg-id-${i}-${region.start}`}
                onDragRegion={onDragRegion}
                onResizeRegion={onResizeRegion}
                onSelectRegion={onSelectRegion}
              />
            )
          )
        )}
      </React.Fragment>
    );
  }
  equalRegions({ start: s1, end: e1 }, { start: s2, end: e2 }) {
    return s1 === s2 && e1 === e2;
  }
  calculationRegionPosition(lineNo, lineHeight, region) {
    const { start, end } = region;
    let { windowStart, pxPerSec, bottom } = this.props;
    return {
      left: (start - windowStart) * pxPerSec,
      width: (end - start) * pxPerSec,
      bottom: bottom + lineNo * lineHeight + 1,
      height: lineHeight - 1,
    };
  }

  splitIntoLines() {
    let { regions } = this.props;
    let lines = [[]];
    regions.forEach((region) => {
      let i = 0;
      for (; i < lines.length; i++) {
        if (!this.hasIntersection(lines[i], region)) {
          break;
        }
      }
      if (i === lines.length) {
        lines.push([]);
      }
      lines[i].push(region);
    });
    return lines;
  }

  hasIntersection(line, region) {
    for (let i = 0; i < line.length; i++) {
      let { start, end } = line[i];
      if (
        utils.between(region.start, start, end) ||
        utils.between(region.end, start, end) ||
        utils.between(start, region.start, region.end) ||
        utils.between(end, region.start, region.end)
      ) {
        return true;
      }
    }
    return false;
  }
}

export default Regions;
