import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { IFaults } from "types/fault";

export default async function getFaults(
  placement: number
): Promise<IFaults | undefined> {
  const url = endpoints.faults.default;
  try {
    const { data } = await axiosNeuron.get(
      `${url}?order_by=&placement=${placement}`
    );

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
