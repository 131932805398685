import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, IActionTypes } from "./types";
import { AppState } from "store";
import { urlFriendlyTZ } from "shared/helpers";
import { getAvailableDates } from "api/handlers/dataLabeling/getAvailableDates";
import { getPlacements } from "api/handlers/dataLabeling/getPlacements";
import { getAvailableRegions } from "api/handlers/dataLabeling/getAvailableRegions";
import { getAvailableLabels } from "api/handlers/dataLabeling/getAvailableLabels";
import { getPlacementTypes } from "api/handlers/dataLabeling/getPlacementTypes";
import { getAudio } from "api/handlers/dataLabeling/getAudio";
import { getAudioRegions } from "api/handlers/dataLabeling/getAudioRegions";
import moment from "moment";
import { getDatetimeTimestamp } from "components/dataLabeling/helpers";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import { getLabelCategories } from "api/handlers/dataLabeling/getLabelCategories";
export const path = "machineDetail/sounds";

const actionData = [
  [EActions.setCommonData, "data", "cb"],
  [EActions.setZoom, "zoom", "placement"],
  [EActions.setFFT, "renderFFT"],
  [EActions.setLoadingBuffer, "placement", "loadingBuffer"],
  [EActions.setBufferLoaded, "placement", "buffer", "rest"],
  [EActions.setDraw, "draw"],
  [EActions.changeTimezone, "data"],
  [EActions.setReset],
  [EActions.setZoomOutLoading, "loading", "placement"],
  [EActions.setBulkZoom, "zoom"],
  [EActions.setPlaying, "placement"],
  [EActions.setLargeDownloadAllowed, "largeDownloadAllowed"],
  [EActions.setVolume, "placement", "volume"],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;

export const fetchStaticData =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { timezoneOffset } = getState().machineDetail.sounds;
      const machine = getState().machineDetail.machine.machine;
      const [yearData, placements] = await Promise.all([
        getAvailableDates(machine.id, timezoneOffset),
        getPlacements(machine.id),
      ]);

      const allLabels = await getAvailableLabels(machine.id);
      const allLabelsCategoryIds: number[] = uniq(
        allLabels
          .map(({ category }: { category: number }) => category)
          .filter((category: number) => Boolean(category))
      );
      const labelCategories = allLabelsCategoryIds.length
        ? await getLabelCategories(allLabelsCategoryIds)
        : [];

      const placementTypesIds = placements
        ? placements.map((placement: any) => placement.type)
        : [];

      const placementTypes = await getPlacementTypes({
        ids: placementTypesIds,
      });

      dispatch(
        actions.setCommonData({
          yearData,
          placements: mapValues(keyBy(placements, "value")),
          labelPlacements: placements,
          placementTypes,
          allLabels,
          labelCategories,
          machine,
        })
      );
    } catch (err) {}
  };

export const fetchDays =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { timezoneOffset } = getState().machineDetail.sounds;
      const machine = getState().machineDetail.machine.machine;
      const yearData = await getAvailableDates(machine.id, timezoneOffset);

      dispatch(
        actions.setCommonData({
          yearData,
        })
      );
    } catch (err) {}
  };

let currentAbortController: any = null;

export const fetchCommonData =
  ({ tz, time, date, duration }: any, cb: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { machine, labelPlacements, yearData } =
        getState().machineDetail.sounds;

      const dDate = date ? date : Object.keys(yearData).slice(-1)[0];

      const dayData = await getAvailableRegions(
        machine.id,
        dDate,
        tz,
        labelPlacements
      );

      if (!time && dDate) {
        if (!time && dayData.length) {
          time = moment.unix(dayData.slice(-1)[0].start).format("HH:mm");
          if (dDate && time) {
            return cb({
              timezoneOffset: urlFriendlyTZ(true, tz),
              date: dDate,
              time,
              duration,
            });
          }
        }
      }

      dispatch(
        actions.setCommonData({
          dayData,
          date: dDate,
          time,
          timezoneOffset: tz,
          machine,
          duration,
        })
      );
      for (let i of labelPlacements) {
        dispatch(
          fetchSound({
            timezoneOffset: tz,
            time,
            date: dDate,
            placement: i.value,
            duration,
          })
        );
      }
    } catch (err) {}
  };

export const fetchSound =
  ({ timezoneOffset, time, date, placement, duration }: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const {
        machine: { id },
        dayData,
        sampleRate,
        loadingCommonData,
      } = getState().machineDetail.sounds;

      if (
        id &&
        date &&
        dayData &&
        time &&
        placement &&
        duration &&
        timezoneOffset &&
        !loadingCommonData
      ) {
        if (currentAbortController) {
          currentAbortController.abort();
        }

        currentAbortController = new AbortController();
        const { signal } = currentAbortController;

        dispatch(actions.setLoadingBuffer(placement, true));
        let start = getDatetimeTimestamp(`${date} ${time}`);
        let end = start + duration;
        if (start) {
          try {
            const [buffer, regions] = await Promise.all([
              getAudio(
                placement,
                start,
                Number(duration),
                timezoneOffset,
                sampleRate,
                signal
              ),
              getAudioRegions(
                id,
                start,
                start + 3600,
                placement,
                timezoneOffset
              ),
            ]);
            dispatch(
              actions.setBufferLoaded(placement, {
                buffer,
                regions,
                bufferStart: start,
                bufferEnd: end,
              })
            );
          } catch (err: any) {
            if (err.name !== "AbortError") {
              // Handle non-abort errors here
              dispatch(actions.setLoadingBuffer(placement, false));
            }
          }
        }
      }
    } catch (err) {
      dispatch(actions.setLoadingBuffer(placement, false));
    }
  };

export const loadBufferSilent =
  (windowStart: number, offset: number) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const {
      machine: { id },
      duration,
      timezoneOffset,
      sampleRate,
      labelPlacements,
    } = getState().machineDetail.sounds;

    currentAbortController = new AbortController();
    const { signal } = currentAbortController;

    const start = windowStart + offset;
    const end = start + duration;
    for (let i of labelPlacements) {
      dispatch(actions.setZoomOutLoading(true, i.value));
      Promise.all([
        getAudio(i.value, start, duration, timezoneOffset, sampleRate, signal),
        // device, start, start + 1h, channel, tz
        getAudioRegions(
          id,
          windowStart,
          windowStart + 3600,
          i.value,
          timezoneOffset
        ),
      ]).then(([buffer, regions]) => {
        // loading wave & labels
        dispatch(
          actions.setBufferLoaded(
            i.value,
            {
              buffer,
              regions,
              bufferStart: start,
              bufferEnd: end,
            },
            {
              zoomOutLoading: false,
            }
          )
        );
      });
    }
  };

export const changeTimezone =
  (newDate: string, newTime: string, tz: string) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { duration, labelPlacements } = getState().machineDetail.sounds;
    const start = getDatetimeTimestamp(newDate + " " + newTime);
    const buffers: any = {};

    if (start) {
      for (let i of labelPlacements) {
        buffers[i.value] = {
          buffer: {
            bufferStart: getDatetimeTimestamp(newDate + " " + newTime),
            bufferEnd: getDatetimeTimestamp(newDate + " " + newTime) + duration,
          },
        };
      }
      dispatch(
        actions.changeTimezone({
          timezoneOffset: tz,
          date: newDate,
          time: newTime,
          buffers,
          bufferStart: getDatetimeTimestamp(newDate + " " + newTime),
        })
      );
    }
  };
